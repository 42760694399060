<template>
  <div class="main">
    <router-view class="router-view" :class="$route.meta.tabbar ? 'height' : ''"></router-view>
    <van-tabbar
      v-model="active"
      route
      v-if="$route.meta.tabbar"
    >
      <van-tabbar-item to="/yisheng/list" name="list" icon="chat-o">咨询</van-tabbar-item>
      <van-tabbar-item to="/yisheng/trending" name="trending" icon="chart-trending-o">统计</van-tabbar-item>
      <van-tabbar-item to="/yisheng/user" name="user" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'yishengEntry',
  data() {
    return {
      active: 'list',
    };
  },
  created() {
    document.title = `${this.$route.meta.title}`;
  },
};
</script>

<style lang="less" scoped>
.main {
  background: #F4F8FB;
  min-height: 100vh;

  .router-view {
    width: 100%;
    // padding: 12px 0;
    overflow-x: hidden;
    box-sizing: border-box;
  }
}
</style>
